import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartData, ChartOptions } from 'chart.js';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { UnidadeBtnSelectTreeComponent } from 'src/app/modules/intranet/components/unidade-btn-select-tree/unidade-btn-select-tree.component';
import { SustSalasComponent } from 'src/app/modules/sustentabilidade/components/sust-salas/sust-salas.component';
import { Unidade } from 'src/app/types/servidor.types';
import { MySharedModule } from '../../../../modules/shared/my-shared.module';
import { SustPlantaBaixaComponent } from '../../../../modules/sustentabilidade/components/sust-planta-baixa/sust-planta-baixa.component';
import { SustRoboService } from '../../../../modules/sustentabilidade/services/sust-robo.service';
import { Leitura, Robo, Sala } from '../../../../modules/sustentabilidade/services/sust-robo.type';
import { Subscriptions } from '../../../../types/system.types';

type DropdownItem = {
	label: string,
	value: any,
	items?: DropdownItem[],
}

type SalasRobos = {
	[key: number]: Leitura
}

export type LeiturasPorUnidade = {
	[idSala: string]: Leitura,
}
export type LeiturasPorTempo = {
	[dt: string]: LeiturasPorUnidade,
}
export type LeiturasPorTipo = {
	[tipoLeitura: string]: LeiturasPorTempo,
}

@Component({
	selector: 'stj-sustentabilidade-predio-page',
	standalone: true,
	imports: [
		MySharedModule,
		SustPlantaBaixaComponent,
		SustSalasComponent,
		UnidadeBtnSelectTreeComponent,
	],
	providers: [
		DialogService,
		SustRoboService,
	],
	templateUrl: './sustentabilidade-predio-page.component.html',
	styleUrl: './sustentabilidade-predio-page.component.scss'
})
export class SustentabilidadePredioPageComponent implements OnInit, OnDestroy
{
	constructor(
		@Inject(Router) private readonly router: Router,
		@Inject(ActivatedRoute) private readonly aroute: ActivatedRoute,
		private roboService: SustRoboService,
	)
	{
		this.maxLength = this.roboService.maxLength;
	}

	loading: boolean = true;
	loadingDados: boolean = true;
	subs: Subscriptions = {};
	maxLength: number;

	salas: { [key: number]: Sala } = {};
	graficoTemperaturaData?: ChartData;
	graficoLuminosidadeData?: ChartData;
	graficoOptions?: ChartOptions = {};
	interval?: any;
	robos: BehaviorSubject<SalasRobos> = new BehaviorSubject({})
	lastDate?: Date;
	unidade?: Unidade;
	leituras: LeiturasPorTempo = {};
	selectedUnidadeId?: number;
	lastValues: any = {};

	@ViewChild('graficoTemperatura') graficoTemperatura: any;
	@ViewChild('graficoLuminosidade') graficoLuminosidade: any;

	get salasArr(): Sala[]
	{
		return Object.values(this.salas) ?? [];
	}

	ngOnInit(): void
	{
		this.initGraficOptions();

		this.subs['url'] = this.aroute.queryParams.subscribe((params: any) =>
		{
			let id_unidade = params.unidade;
			this.selectedUnidadeId = id_unidade;
		});
	}

	ngOnDestroy(): void
	{
		for (let s in this.subs) this.subs[s].unsubscribe();
		if (this.interval) clearInterval(this.interval);
	}

	async pegaSensores()
	{
		if (!this.selectedUnidadeId)
		{
			this.loading = false;
			return;
		}
		this.salas = {};
		let salas: any = {};
		this.loading = true;
		let sensores = await this.roboService.getRobos(this.selectedUnidadeId);
		// console.log(sensores);
		for (let sensor of sensores?.items)
		{
			let sala: Sala = salas[sensor.id_unidade];
			if (!sala)
			{
				sala = {} as Sala;
				sala.id = sensor.id_unidade;
				sala.label = sensor.unidade?.sigla_unidade;
				sala.unidade = sensor.unidade;
				sala.dados = sensor.dados;
			}
			if (sensor.tipo == 0)
			{
				sala.roboLuz = sensor;
			}
			else
			{
				if (!sala.robosJanela) sala.robosJanela = {};
				sala.robosJanela[sensor.id] = sensor;
			}
			salas[sensor.id_unidade] = sala;
		}
		this.salas = salas;

		this.carrega();
		this.loading = false;
	}

	private initGraficOptions()
	{
		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
		const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

		this.graficoOptions = {
			// stacked: false,
			maintainAspectRatio: false,
			aspectRatio: 0.6,
			plugins: {
				legend: {
					labels: {
						color: textColor
					}
				}
			},
			scales: {
				x: {
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder
					}
				},
				y: {
					type: 'linear',
					display: true,
					position: 'left',
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder
					},
					min: 0,
				},
				y0: {
					type: 'linear',
					display: 'auto',
					position: 'right',
					ticks: {
						color: textColorSecondary
					},
					grid: {
						drawOnChartArea: false,
						color: surfaceBorder
					},
					min: 0,
					max: 100,
				},
				y1: {
					type: 'linear',
					display: 'auto',
					position: 'right',
					ticks: {
						color: textColorSecondary
					},
					grid: {
						drawOnChartArea: false,
						color: surfaceBorder
					},
					min: 0,
				},
			}
		};
	}

	refresh()
	{
		this.onSelectUnidade(this.unidade)
	}

	onSelectUnidade(unidade?: Unidade)
	{
		this.unidade = unidade;
		let qParams: any = {};
		// console.log(unidade, this.aroute.snapshot.params)
		if (unidade) qParams = { unidade: unidade?.id_unidade }
		else if (this.aroute.snapshot.params['unidade']) qParams = { unidade: this.aroute.snapshot.params['unidade'] }
		// console.log(qParams);
		this.router.navigate([], { relativeTo: this.aroute, queryParams: qParams });
		this.selectedUnidadeId = qParams.unidade;

		if (unidade)
		{
			this.graficoLuminosidadeData = undefined;
			this.graficoTemperaturaData = undefined;
			this.lastDate = undefined;
			this.pegaSensores();
		}
	}

	async carrega()
	{
		let idsUnidades: any[] = Object.keys(this.salas);
		if (this.loadingDados === undefined) this.loadingDados = true;
		let group = 1;
		// let limit = 800;
		let leituras = await this.roboService.getDadosSalas(idsUnidades, this.lastDate, group, this.roboService.maxLength);
		let leiturasParcial: LeiturasPorTipo = {};

		for (let leitura of leituras)
		{
			if (!leitura.robo || !leitura.robo.id) continue;

			let robo = leitura.robo;
			if (!robo.id) continue;
			let sala = this.salas[robo.id_unidade!];
			if (!sala) continue;

			let key1;
			let key2;
			let key3;

			if (leitura.qtJanelas != null)
			{
				// if (!sala.robosJanela![robo.id]) sala.robosJanela![robo.id] = new Robo(robo);
				sala.robosJanela![robo.id] = new Robo(robo);
				if (!sala.robosJanela![robo.id].leituras) sala.robosJanela![robo.id].leituras = [];
				sala.robosJanela![robo.id].leituras?.push(leitura);
				if ((sala.robosJanela![robo.id]?.leituras?.length ?? 0) > 100) sala.robosJanela![robo.id].leituras?.shift();
			}
			if (leitura.temperatura != null)
			{
				key1 = 'temperatura';
				let dt = new Date(leitura.dtLastView ?? '');
				dt.setSeconds(0, 0);
				key2 = dt.getTime()?.toString() ?? '';

				if (!leiturasParcial[key1]) leiturasParcial[key1] = {};

				if (!leiturasParcial[key1][key2]) leiturasParcial[key1][key2] = {};

				key3 = leitura.robo.id_unidade?.toString() ?? '';
				leiturasParcial[key1][key2][key3] = leitura.temperatura ? leitura : this.lastValues[key1][key3];

				if (!this.lastValues) this.lastValues = {};
				if (!this.lastValues[key1]) this.lastValues[key1] = {};
				this.lastValues[key1][key3] = leitura;
			}
			if (leitura.luminosidade != null)
			{
				key1 = 'luz';
				let dt = new Date(leitura.dtLastView ?? '');
				dt.setSeconds(0, 0);
				key2 = dt.getTime()?.toString() ?? '';
				if (!leiturasParcial[key1]) leiturasParcial[key1] = {};

				if (!leiturasParcial[key1][key2]) leiturasParcial[key1][key2] = {};

				key3 = leitura.robo.id_unidade?.toString() ?? '';
				leiturasParcial[key1][key2][key3] = leitura.luminosidade ? leitura : this.lastValues[key1][key3];

				// if (!sala.roboLuz) sala.roboLuz = new Robo(robo);
				sala.roboLuz = new Robo(robo);
				if (!sala.roboLuz.leituras) sala.roboLuz.leituras = [];
				sala.roboLuz.leituras?.push(leitura);
				if (sala.roboLuz.leituras.length > 100) sala.roboLuz.leituras.shift();

				if (!this.lastValues) this.lastValues = {};
				if (!this.lastValues[key1]) this.lastValues[key1] = {};
				this.lastValues[key1][key3] = leitura;
			}
			if (!sala.leiturasRobos) sala.leiturasRobos = {};
			if (!sala.leiturasRobos[robo.id])
			{
				sala.leiturasRobos[robo.id] = new BehaviorSubject<Leitura>(leitura);
			}
			else
			{
				sala.leiturasRobos[robo.id].next(leitura);
			}

			this.lastDate = leitura.dtLastView;
		}

		this.montaGraficos(leiturasParcial);
		this.loadingDados = false;

		this.interval = setTimeout(() =>
		{
			this.carrega();
		}, leituras.length ? 5000 : 60000);

		return;
	}

	private montaGraficos(leiturasParcial: LeiturasPorTipo)
	{
		if (Object.keys(leiturasParcial).length == 0) return;
		// console.log(leiturasParcial);

		this.montaGraficoTemperatura(leiturasParcial['temperatura']);
		this.montaGraficoLuminosidade(leiturasParcial['luz']);

		this.leituras = { ...this.leituras, ...leiturasParcial };
	}

	private montaData(leitura: Leitura): Date
	{
		let dt = new Date();
		if (leitura.minute !== undefined) dt.setMinutes(leitura.minute, 0, 0); else dt.setMinutes(0, 0, 0);
		if (leitura.hour !== undefined) dt.setHours(leitura.hour); else dt.setHours(0, 0, 0, 0);
		if (leitura.day !== undefined) dt.setDate(leitura.day);
		if (leitura.month !== undefined) dt.setMonth(leitura.month - 1);
		if (leitura.year !== undefined) dt.setFullYear(leitura.year);

		return dt;
	}

	montaGraficoTemperatura(leiturasParcial: LeiturasPorTempo)
	{
		let grafico = this.graficoTemperaturaData;

		if (!grafico)
		{
			grafico = {
				labels: [],
				datasets: []
			};
			let j = 0;

			for (let id_unidade in this.salas)
			{
				let sala = this.salas[id_unidade];
				let n = Number(j) * 3;

				grafico.datasets[n] = {
					label: `${sala.label} - Temperatura (°C)`,
					fill: false,
					yAxisID: 'y',
					// tension: 0.3,
					data: [],
				};
				grafico.datasets[n + 1] = {
					label: `${sala.label} - Janela(s) Aberta(s)`,
					fill: false,
					borderDash: [5, 5],
					yAxisID: 'y1',
					// tension: 0.3,
					data: [],
				};
				grafico.datasets[n + 2] = {
					label: `${sala.label} - Umidade (%)`,
					fill: false,
					borderDash: [5, 5],
					yAxisID: 'y0',
					// tension: 0.3,
					data: [],
				};
				j++;
			}
		}

		let lastLeitura;
		for (let dtx in leiturasParcial)
		{
			let dt = new Date();
			dt.setTime(Number(dtx));
			dt.setSeconds(0, 0);
			let unidadesLeituras: LeiturasPorUnidade = leiturasParcial[dtx];

			// Datas
			// let dtx: Date = this.montaData(leitura);
			grafico.labels?.push((new Date(dt)).toLocaleString());
			if ((grafico.labels?.length ?? 0) > this.maxLength)
			{
				grafico.labels?.shift();
			}

			let i = -1;
			for (let id_unidade in this.salas)
			{
				i++;
				// 				let i = Object.keys(this.salas).findIndex(key => key == leitura.robo?.id_unidade?.toString());
				let n = Number(i) * 3;
				let leitura: Leitura = unidadesLeituras[id_unidade];
				if (!leitura)
				{
					let data = grafico.datasets[n].data
					let lastEntry = data.length ? data[data.length - 1] : undefined;

					leitura = {
						minute: lastLeitura?.minute,
						hour: lastLeitura?.hour,
						day: lastLeitura?.day,
						month: lastLeitura?.month,
						year: lastLeitura?.year,
					};
					if (lastLeitura?.temperatura === undefined)
					{
						leitura.temperatura = null;
					} else
					{
						leitura.temperatura = Number(lastEntry) || 0;
					}
					// leitura = { ...lastLeitura };
				}
				else
				{
					lastLeitura = leitura;
				}

				// Temperatura
				grafico.datasets[n]?.data.push(leitura.temperatura === null ? null : (leitura.temperatura ?? 0));
				if ((grafico.datasets[n]?.data?.length ?? 0) > this.maxLength)
				{
					grafico.datasets[n]?.data?.shift();
				}

				// Janelas
				grafico.datasets[n + 1]?.data.push(leitura.janelaAberta ? 1 : 0);
				if ((grafico.datasets[n + 1]?.data?.length ?? 0) > this.maxLength)
				{
					grafico.datasets[n + 1]?.data?.shift();
				}

				// Umidade
				grafico.datasets[n + 2]?.data.push(leitura.umidade ? leitura.umidade : null);
				if ((grafico.datasets[n + 2]?.data?.length ?? 0) > this.maxLength)
				{
					grafico.datasets[n + 2]?.data?.shift();
				}
			}
		}

		// let i = this.salas.findIndex(sala => sala.label == leitura.siglaUnidade);

		this.graficoTemperaturaData = grafico;

		if (this.graficoTemperatura)
		{
			this.graficoTemperatura.chart?.update();
		}
	}

	montaGraficoLuminosidade(leiturasParcial: LeiturasPorTempo)
	{
		let grafico = this.graficoLuminosidadeData;
		if (!grafico)
		{
			grafico = {
				labels: [],
				datasets: []
			};
			let j = 0;
			for (let id_unidade in this.salas)
			{
				let sala = this.salas[id_unidade];
				let n = Number(j) * 2;
				grafico.datasets[n] = {
					label: `${sala.label} - Luminosidade (%)`,
					fill: false,
					yAxisID: 'y',
					// tension: 0.3,
					data: [],
				};
				grafico.datasets[n + 1] = {
					label: `${sala.label} - Sala vazia`,
					fill: false,
					yAxisID: 'y1',
					borderDash: [5, 5],
					// tension: 0.3,
					data: [],
				};
				j++;
			}
			grafico.datasets.push({
				label: 'Limite para Luzes acesas (%)',
				fill: false,
				yAxisID: 'y',
				tension: 0.3,
				borderDash: [5, 5],
				data: [],
			});
		}

		let lastLeitura;
		for (let dtx in leiturasParcial)
		{
			let dt = new Date();
			dt.setTime(Number(dtx));
			dt.setSeconds(0, 0);
			let unidadesLeituras: LeiturasPorUnidade = leiturasParcial[dtx];

			grafico.labels?.push((dt).toLocaleString());
			if ((grafico.labels?.length ?? 0) > this.maxLength)
			{
				grafico.labels?.shift();
			}

			// Luz limite no último dataset
			let j = grafico.datasets.length - 1;
			// let luzLimite = Math.round(((leitura.luzLimite ?? 550) / 1024) * 100);
			let luzLimite = Math.round(((400) / 1024) * 100);
			grafico.datasets[j]?.data.push(luzLimite);
			if ((grafico.datasets[j]?.data?.length ?? 0) > this.maxLength)
			{
				grafico.datasets[j]?.data?.shift();
			}

			let i = -1;
			for (let id_unidade in this.salas)
			{
				i++;
				let n = Number(i) * 2;
				let leitura = unidadesLeituras[id_unidade];
				if (!leitura)
				{
					let data = grafico.datasets[n].data
					let lastEntry = data.length ? data[data.length - 1] : undefined;
					// console.log(lastEntry);
					leitura = {
						minute: lastLeitura?.minute,
						hour: lastLeitura?.hour,
						day: lastLeitura?.day,
						month: lastLeitura?.month,
						year: lastLeitura?.year,
					};
					if (lastLeitura?.temperatura !== undefined) leitura.temperatura = Number(lastEntry) || 0;
					// leitura = { ...lastLeitura };
				}
				else
				{
					lastLeitura = leitura;
				}

				// Luminosidade
				let luminosidade: number | null = null;
				if (leitura.luminosidade !== undefined && leitura.luminosidade !== null)
				{
					let luzLeitura = Number(leitura.luminosidade) ?? 1024;
					if (isNaN(luzLeitura)) luzLeitura = 1024;
					luminosidade = Math.round(((luzLeitura) / 4500) * 1000) / 10;
				}
				grafico.datasets[n]?.data.push(luminosidade);

				if ((grafico.datasets[n]?.data?.length ?? 0) > this.maxLength)
				{
					grafico.datasets[n]?.data?.shift();
				}

				// Presença
				let presente = leitura.presente === null ? null : (leitura.presente == false ? 1 : 0);

				grafico.datasets[n + 1]?.data.push(presente);
				if ((grafico.datasets[n + 1]?.data?.length ?? 0) > this.maxLength)
				{
					grafico.datasets[n + 1]?.data?.shift();
				}
			}
		}

		this.graficoLuminosidadeData = grafico;

		if (this.graficoLuminosidade)
		{
			this.graficoLuminosidade.chart?.update();
		}
	}
}
