<p-accordion [activeIndex]="[0,1,2,3]" [multiple]="true">
	<p-accordionTab header="Dados atuais" *ngIf="robo">
		<div class="dados">
			<table class="tabela-dados">
				<tbody>
					<tr>
						<td><i class="fas fa-sharp fa-regular fa-microchip fa-lg" style='color:#0B0B61'></i></td>
						<td>Dispositivo:</td>
						<td>{{robo.dispositivo}}</td>
					</tr>
					<tr>
						<td><i class="fas fa-solid fa-wifi fa-lg"></i></td>
						<td>IP:</td>
						<td>{{robo.ip}}</td>
					</tr>
					<tr>
						<td><i class="fas fa-solid fa-wifi fa-lg"></i></td>
						<td>Mac Address:</td>
						<td>{{robo.mac}}</td>
					</tr>
					<tr *ngIf="robo.qtJanelas">
						<td><i class='fas fa-image fa-2x' style='color:blue'></i></td>
						<td>Janelas:</td>
						<td>{{robo.qtJanelas}}</td>
					</tr>
					<tr *ngIf="robo.temperatura != undefined">
						<td><i class='fas fa-thermometer-half fa-2x' style='color:red'></i></td>
						<td>Temperatura:</td>
						<td>{{robo.temperatura}}</td>
					</tr>
					<tr *ngIf="robo.umidade != undefined">
						<td><i class='fas fa-tint fa-2x' style='color:#00add6'></i></td>
						<td>Umidade:</td>
						<td>{{robo.umidade}} %</td>
					</tr>
					<tr *ngIf="luminosidade != undefined">
						<td><i class='fas fa-lightbulb fa-2x' style='color:#DAA801'></i></td>
						<td>Luminosidade:</td>
						<td>{{(luminosidade || 0) | number: '1.0-2'}} %</td>
					</tr>
					<tr *ngIf="robo.presente === false || robo.presente === true">
						<td><i class='fas fa-walking fa-2x' ></i></td>
						<td>Presença:</td>
						<td>{{robo.presente ? 'SIM' : 'NÃO'}}{{robo.presente | json}}</td>
					</tr>
					<tr>
						<td><i class="fas fa-regular fa-calendar fa-2x" style='color:#0A2A0A'></i></td>
						<td>Data:</td>
						<td>{{now | date: 'short'}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</p-accordionTab>
	<p-accordionTab header="Histórico de Temperatura" *ngIf="(((graficoTemperaturaData?.datasets || [])[0])?.data?.length||0) > 0">
		<div class="card">
			<p-chart type="line" #graficoTemperatura [data]="graficoTemperaturaData" [options]="graficoOptions" [height]="'300px'"></p-chart>
		</div>
	</p-accordionTab>
	<p-accordionTab header="Histórico dos Sensores" *ngIf="(((graficoLuminosidadeData?.datasets || [])[0])?.data?.length||0) > 0">
		<div class="card">
			<p-chart type="line" #graficoLuminosidade [data]="graficoLuminosidadeData" [options]="graficoOptions" [height]="'300px'"></p-chart>
			<!-- <pre>{{graficoLuminosidadeData | json}}</pre> -->
		</div>
	</p-accordionTab>
	<p-accordionTab header="Abertura de Janelas" *ngIf="(((graficoJanelasData?.datasets || [])[0])?.data?.length||0) > 0">
		<div class="card">
			<p-chart type="line" #graficoJanelas [data]="graficoJanelasData" [options]="graficoOptions" [height]="'300px'"></p-chart>
		</div>
	</p-accordionTab>
</p-accordion>
