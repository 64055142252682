<p-splitter [style]="{ height: '70vh' }" styleClass="mb-5">
	<ng-template pTemplate>
		<div class="col flex flex-column align-items-center justify-content-start">
			<div class="dados" *ngIf="robo">
				<table class="tabela-dados">
					<tbody>
						<tr>
							<td><i class="fas fa-sharp fa-regular fa-microchip fa-lg" style='color:#0B0B61'></i></td>
							<td>Dispositivo:</td>
							<td>{{robo.dispositivo}}</td>
						</tr>
						<tr>
							<td><i class="fas fa-sharp fa-regular fa-sitemap fa-lg" style='color:#0B0B61'></i></td>
							<td>Unidade:</td>
							<td>{{robo.unidade?.sigla_unidade || '-'}}
								<span *ngIf="selectedItem" class="destaque"> &rarr; {{selectedItem.sigla_unidade}}</span>
							</td>
						</tr>
						<tr>
							<td><i class="fas fa-solid fa-wifi fa-lg"></i></td>
							<td>IP:</td>
							<td>{{robo.ip}}</td>
						</tr>
						<tr>
							<td><i class="fas fa-solid fa-wifi fa-lg"></i></td>
							<td>Mac Address:</td>
							<td>{{robo.mac}}</td>
						</tr>
						<tr *ngIf="robo.qtJanelas !== undefined">
							<td><i class='fas fa-image fa-2x' style='color:blue'></i></td>
							<td>Janelas:</td>
							<td>{{robo.qtJanelas}}</td>
						</tr>
						<tr *ngIf="robo.temperatura != undefined">
							<td><i class='fas fa-thermometer-half fa-2x' style='color:red'></i></td>
							<td>Temperatura:</td>
							<td>{{robo.temperatura}}</td>
						</tr>
						<tr *ngIf="robo.umidade != undefined">
							<td><i class='fas fa-tint fa-2x' style='color:#00add6'></i></td>
							<td>Umidade:</td>
							<td>{{robo.umidade}} %</td>
						</tr>
						<tr *ngIf="robo.luminosidade != undefined">
							<td><i class='fas fa-lightbulb fa-2x' style='color:#DAA801'></i></td>
							<td>Luminosidade:</td>
							<td>{{Math.round(((robo.luminosidade)/1024)*100)}} %</td>
						</tr>
						<tr *ngIf="robo.presente === false || robo.presente === true">
							<td><i class='fas fa-walking fa-2x'></i></td>
							<td>Presença:</td>
							<td>{{robo.presente ? 'SIM' : 'NÃO'}}{{robo.presente | json}}</td>
						</tr>
						<tr>
							<td><i class="fas fa-regular fa-calendar fa-2x" style='color:#0A2A0A'></i></td>
							<td>Data:</td>
							<td>{{now | date: 'short'}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="acoes gap-4 flex flex-column align-items-center justify-content-center mt-4">
				<p-buttonGroup>
					<p-button label="Salvar alterações" icon="pi pi-check" severity="success" [disabled]="!selectedItem" [loading]="loadingSave" (click)="actionSave()" />
					<p-button label="Cancelar alterações" icon="pi pi-times" severity="warning" (click)="actionClose()" />
				</p-buttonGroup>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate>
		<div class="col flex align-items-start justify-content-center">
			<p-scrollPanel [style]="{ width: '100%', height: '69vh' }">
				<p-table
					dataKey="id_unidade"
					[value]="unidades"
					styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
					[paginator]="true"
					[rows]="30"
					sortField="sigla_unidade"
					[totalRecords]="total"
					[(selection)]="selectedItem"
					selectionMode="single" 
					[globalFilterFields]="['sigla_unidade','nome_unidade']"
					[loading]="loading">
					<ng-template pTemplate="header">
						<tr>
							<th class="sigla text-center">Sigla</th>
							<th class="nome text-center">Nome</th>
						</tr>
						<tr>
							<th class="sigla">
								<p-columnFilter 
									type="text"
									field="sigla_unidade"
									placeholder="Busca pela Sigla"
									aria-label="Filtro por Sigla da Unidade"
								/>
							</th>
							<th class="nome">
								<p-columnFilter 
									type="text"
									field="nome_unidade"
									placeholder="Busca pelo Nome"
									aria-label="Filtro por Nome da Unidade"
								/>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-item>
						<tr [pSelectableRow]="item">
							<td class="sigla">{{item.sigla_unidade}}</td>
							<td class="nome">{{item.nome_unidade}}</td>
						</tr>
					</ng-template>
				</p-table>
			</p-scrollPanel>
		</div>
	</ng-template>
</p-splitter>