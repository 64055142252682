import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SustUnidadeEditComponent } from 'src/app/modules/sustentabilidade/components/sust-unidade-edit/sust-unidade-edit.component';
import { UnidadeBtnSelectTreeComponent } from '../../../../modules/intranet/components/unidade-btn-select-tree/unidade-btn-select-tree.component';
import { Column } from '../../../../modules/servidor/components/usuario/usuario-data-grid/usuario-data-grid.component';
import { MySharedModule } from '../../../../modules/shared/my-shared.module';
import { MyMessageService } from '../../../../modules/shared/services/my-message.service';
import { SustDetalheRoboComponent } from '../../../../modules/sustentabilidade/components/sust-detalhe-sensor/sust-detalhe-sensor.component';
import { SustRoboService } from '../../../../modules/sustentabilidade/services/sust-robo.service';
import { Leitura, SUST_ROBO_TIPO } from '../../../../modules/sustentabilidade/services/sust-robo.type';
import { Unidade } from '../../../../types/servidor.types';
import { Subscriptions } from '../../../../types/system.types';

@Component({
	selector: 'stj-sustentabilidade-robo-admin',
	standalone: true,
	imports: [
		MySharedModule,
		UnidadeBtnSelectTreeComponent,
	],
	providers: [
		DialogService,
		SustRoboService,
		MyMessageService,
	],
	templateUrl: './sustentabilidade-robo-admin.component.html',
	styleUrl: './sustentabilidade-robo-admin.component.scss'
})
export class SustentabilidadeRoboAdminComponent implements OnInit, OnDestroy
{
	constructor (
		private roboService: SustRoboService,
		private msgService: MyMessageService,
		public dialogService: DialogService,
	) { }

	ref: DynamicDialogRef | undefined;
	titulo?: string;
	selectedUnidade?: number;
	loading: boolean = false;
	items: Leitura[] = [];
	unidades: any[] = [];
	subs: Subscriptions = {};
	colunas: Column[] = [];
	total: number = 0;
	cmItems: MenuItem[] = [];
	selectedItem?: Leitura;

	ngOnInit(): void
	{
		// this.titulo = `Lista de atividades`;
		this.configuraColunas();
		// this.refresh();
	}

	ngOnDestroy(): void
	{
		// this.roboService.disconnectSocket();
	}

	configuraColunas()
	{
		this.colunas = [];
		this.colunas.push({
			header: 'ID',
			field: 'id',
			align: 'center',
		} as Column);
		this.colunas.push({
			header: 'Nome',
			field: 'dispositivo',
			align: 'center',
		} as Column);
		this.colunas.push({
			header: 'Tipo',
			field: 'tipo',
			align: 'center',
		} as Column);
		this.colunas.push({
			header: 'IP',
			field: 'ip',
			align: 'center',
		} as Column);
		this.colunas.push({
			header: 'Unidade',
			field: 'unidade.sigla_unidade',
			align: 'center',
		} as Column);

		this.cmItems = [
			{
				label: 'Editar unidade',
				command: (event) =>
				{
					this.editItem(this.selectedItem!);
				}
			},
			{
				label: 'Visualizar dados do sensor',
				command: (event) =>
				{
					this.viewItem(this.selectedItem!);
					// this.router.navigateByUrl(`/sustentabilidade/robo/${this.selectedItem?.id}`)
				},
			}
		]
	}

	viewItem(item: Leitura)
	{
		let args: DynamicDialogConfig = {
			header: `Detalhes do Robo '${item.dispositivo}'`,
			position: 'right',
			modal: true,
			closeOnEscape: true,
			width: '50vw',
			height: '99vh',
			breakpoints: {
				'960px': '75vw',
				'640px': '90vw'
			},
			data: {
				sensor: item,
			}
		};
		this.ref = this.dialogService.open(SustDetalheRoboComponent, args);
		this.ref.onClose.subscribe((saida: any) =>
		{
			console.log('close', saida);
		});
	}

	editItem(item: Leitura)
	{
		let args: DynamicDialogConfig = {
			header: `Gerenciar Robo '${item.dispositivo}'`,
			position: 'right',
			modal: true,
			closeOnEscape: true,
			width: '50vw',
			height: '99vh',
			breakpoints: {
				'960px': '75vw',
				'640px': '90vw'
			},
			data: {
				sensor: item,
			}
		};
		this.ref = this.dialogService.open(SustUnidadeEditComponent, args);
		this.ref.onClose.subscribe((saida: any) =>
		{
			if (saida == 'save')
			{
				this.refresh();
			}
		});
	}

	onSelectUnidade(unidade?: Unidade)
	{
		this.selectedUnidade = unidade?.id_unidade;
		this.refresh();
	}

	async refresh()
	{
		this.loading = true;
		if (!this.selectedUnidade) return;
		this.items = [];
		try
		{
			let { items, total } = await this.roboService.getRobos(this.selectedUnidade);
			this.items = items;

			this.total = total;
			// this.testaWS(items[0]);
		} catch (error: any)
		{
			console.log(error);
			this.msgService.error(error);
		} finally
		{
			// if (this.items.length) this.editItem(this.items[0])
			this.loading = false;
		}
	}

	pegaValor(item: any, campo: string): any
	{
		if (campo == 'tipo')
		{
			return (SUST_ROBO_TIPO as any)[item.tipo as string];
		}
		let campos = campo.split('.');
		if (campos.length == 1) return item[campo];
		let res = item;
		for (let c of campos)
		{
			if (!res) return undefined;
			res = res[c];
		}
		return res;
	}

}
