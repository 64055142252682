import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Unidade } from 'src/app/types/servidor.types';
import { environment } from '../../../../environment/environment';
import { DefaultService } from '../../shared/services/-default.service';
import { MyMessageService } from '../../shared/services/my-message.service';
import { Leitura, Robo, Sala, Salas } from './sust-robo.type';

export type ResultadoPaginado = {
	total: number;
	items: any[];
}

@Injectable({
	providedIn: 'root'
})
export class SustRoboService extends DefaultService
{
	constructor (
		http: HttpClient,
		private msgService: MyMessageService,
	)
	{
		super(http);

		// this.webSocket = new Socket({
		// 	url: `http://localhost:3000/`,
		// 	options: {
		// 		autoConnect: false,
		// 	}
		// });

		this.initSalas();
	}

	selectedRobo: BehaviorSubject<Leitura | undefined> = new BehaviorSubject<Leitura | undefined>(undefined);
	salas: BehaviorSubject<Salas> = new BehaviorSubject({} as Salas);
	historico: Salas[] = [] as Salas[];
	maxLength: number = 1440;

	// private webSocket: Socket;

	listaSalas = [];

	getLuminosidadePerc(luzLeitura?: number | null)
	{
		if (luzLeitura === undefined || luzLeitura === null) return null;
		let luminosidade: number | null = null;
		// let luzLeitura = Number(leitura.luminosidade) ?? 1024;
		if (isNaN(luzLeitura)) luzLeitura = 1024;
		luminosidade = Math.round(((luzLeitura) / 4500) * 1000) / 10;
		return luminosidade;
	}

	async getRobo(idRobo?: number): Promise<Robo>
	{
		let url = new URL(`${window.location.origin}/api-sust/robo/sensor/${idRobo}`);
		let dados: any;
		try
		{
			dados = await this._genericGet(url.href) as Robo;
		} catch (error: any)
		{
			this.msgService.add({ severity: 'error', summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}` });
		} finally
		{
			return dados;
		}
	}

	async getRobos(id_unidade?: number, page?: number): Promise<ResultadoPaginado>
	{
		let url = new URL(`${window.location.origin}/api-sust/robo/sensores`);
		if (page)
		{
			url.searchParams.set('page', page.toString());
		}
		// let dt = new Date();
		// let dispositivo = 'servidor';
		// url.searchParams.set('tempo', dt.toISOString());
		// url.searchParams.set('id', dispositivo);
		// url.searchParams.set('hash', await this.getHash(dispositivo, dt));
		if (id_unidade) url.searchParams.set('id_unidade', id_unidade.toString());


		let dados: any = {
			items: [],
			total: 0,
		};
		try
		{
			dados = await this._genericGet(url.href) as ResultadoPaginado;
		} catch (error: any)
		{
			this.msgService.add({ severity: 'error', summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}` });
		} finally
		{
			return dados;
		}
	}

	async initSalas()
	{

		let salas: Salas = {};
		for (let nomeSala of this.listaSalas)
		{
			let sala = await this.initSala(nomeSala, 0);
			sala.height = 96;
			sala.width = 14.5;
			salas[nomeSala] = sala;
		}

		// salas['caut'] = {
		// 	...salas['caut'],
		// 	label: 'CAUT',
		// 	left: 74.5,
		// 	top: 1.5,
		// 	width: 17.2,
		// 	roboLuz: {
		// 		id: 2,
		// 		dispositivo: 'caut-01',
		// 	},
		// 	robosJanela: [
		// 		{
		// 			id: 1,
		// 			dispositivo: 'caut-02',
		// 		},
		// 	],
		// } as Sala
		salas['caug'] = {
			...salas['caug'],
			label: 'CAUG',
			left: 58,
			top: 1.5,
			width: 16.9,
		}
		salas['caup'] = {
			...salas['caup'],
			label: 'CAUP',
			left: 46.9,
			top: 1.5,
			width: 11.4,
		}

		this.salas.next(salas);

		// this.addHistorico();

		return salas;
	}

	async initSala(nome: string, janelas?: number): Promise<Sala>
	{
		let robo: Leitura = new Leitura({
			dispositivo: `${nome}-sensor-01`,
			ip: ``,
			luzLimite: 500,
			luminosidade: 0,
			umidade: 0,
			temperatura: 0,
			presente: false,
			janelaAberta: false,
		});
		let sala: Sala = {
			id: 0,
			leiturasRobos: {},
			// roboLuz: robo,
		}
		return sala;
	}

	async getDados(roboId?: number | number[], fromDate?: Date): Promise<Leitura[]>
	{
		let url = new URL(`${window.location.origin}/api-sust/robo/leituras`);
		// let dt = new Date();
		// let dispositivo = 'servidor';
		let dispositivos: number[] = (typeof roboId == 'number' ? [roboId] : roboId) ?? [];
		// url.searchParams.set('tempo', dt.toISOString());
		// url.searchParams.set('id', dispositivo);
		// url.searchParams.set('hash', await this.getHash(dispositivo, dt));
		url.searchParams.set('dispositivos', dispositivos.toString());
		if (fromDate)
		{
			url.searchParams.set('from', (typeof fromDate == 'string') ? fromDate : fromDate.toISOString());
		}

		let dados: Leitura[] = []
		try
		{
			dados = await this._genericGet(url.href) as Leitura[];
		} catch (error: any)
		{
			this.msgService.add({ severity: 'error', summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}` });
		} finally
		{
			return dados;
		}
	}

	async getHistoricoDia(id_unidade: number, refDate?: Date): Promise<any[]>
	{
		let url = new URL(`${window.location.origin}/api-sust/robo/resumo`);
		let dt = new Date();
		url.searchParams.set('id_unidade', id_unidade.toString());
		// let dispositivos: number[] = (typeof roboId == 'number' ? [roboId] : roboId) ?? [];
		// url.searchParams.set('tempo', dt.toISOString());
		// url.searchParams.set('hash', await this.getHash(dispositivo, dt));
		// url.searchParams.set('dispositivos', dispositivos.toString());
		if (!refDate) refDate = dt;
		url.searchParams.set('dia', (typeof refDate == 'string') ? refDate : refDate.toISOString());

		let dados: Leitura[] = []
		try
		{
			dados = await this._genericGet(url.href) as any[];
		} catch (error: any)
		{
			this.msgService.add({ severity: 'error', summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}` });
		} finally
		{
			return dados;
		}
	}

	async enviaHistoricoDia(id_unidade: number, refDate?: Date): Promise<any[]>
	{
		let url = new URL(`${window.location.origin}/api-sust/robo/teams`);
		let dt = new Date();
		url.searchParams.set('id_unidade', id_unidade.toString());
		if (!refDate) refDate = dt;
		url.searchParams.set('dia', (typeof refDate == 'string') ? refDate : refDate.toISOString());

		let dados: Leitura[] = []
		try
		{
			dados = await this._genericGet(url.href) as any[];
		} catch (error: any)
		{
			this.msgService.add({ severity: 'error', summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}` });
		} finally
		{
			return dados;
		}
	}

	async getDadosSalas(salas?: number[], fromDate?: Date, group: number = 0, limit: number = 200): Promise<Leitura[]>
	{
		let url = new URL(`${window.location.origin}/api-sust/robo/leituras`);
		// let dt = new Date();
		// let dispositivo = 'servidor';
		// let dispositivos:number[] = (typeof roboId == 'number' ? [roboId] : roboId)??[];
		// url.searchParams.set('tempo', dt.toISOString());
		// url.searchParams.set('id', dispositivo);
		// url.searchParams.set('hash', await this.getHash(dispositivo, dt));
		url.searchParams.set('unidades', salas?.toString() ?? '');
		url.searchParams.set('group', group.toString());
		url.searchParams.set('limit', limit.toString());

		if (fromDate)
		{
			fromDate = new Date(fromDate);
			// fromDate.setMinutes(fromDate.getMinutes() - fromDate.getTimezoneOffset());
			// console.log(fromDate.getTimezoneOffset(), fromDate);
			url.searchParams.set('from', (typeof fromDate == 'string') ? fromDate : fromDate.toISOString());
		}

		let dados: Leitura[] = []
		try
		{
			dados = await this._genericGet(url.href) as Leitura[];
		} catch (error: any)
		{
			this.msgService.add({ severity: 'error', summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}` });
		} finally
		{
			return dados;
		}
	}

	async salvaUnidadeRobo(robo: Leitura, unidade?: Unidade): Promise<any | undefined>
	{
		// return undefined;
		let url = new URL(`${window.location.origin}/api-sust/robo/unidade`);
		// 		if (page)
		// 		{
		// 			url.searchParams.set('page', page.toString());
		// 		}
		// 		if (id_unidade) url.searchParams.set('id_unidade', id_unidade.toString());
		//
		let payload: any = {
			robo,
			unidade,
		};
		
		let dados: any = {
			generatedMaps: [],
			identifiers: [],
			raw: {}
		};
		try
		{
			dados = await this._genericPost(url.href, payload);
		} catch (error: any)
		{
			this.msgService.add({ severity: 'error', summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}` });
		} finally
		{
			return dados?.generatedMaps;
		}
	}

	// getUsers()
	// {
	// 	return this.socket.fromEvent('users');
	// }

	// getRoboInit(roboId: string)
	// {

	// }

	// // this method is used to start connection/handhshake of socket with server
	// public connectSocket(message: string)
	// {
	// 	this.webSocket.connect();
	// 	this.webSocket.emit('roboConnect', message);
	// }

	// // this method is used to get response from server
	// public receiveStatus()
	// {
	// 	const wse = this.webSocket.fromEvent('leitura');
	// 	return wse;
	// }

	// // this method is used to end web socket connection
	// public disconnectSocket(message?: string)
	// {
	// 	this.webSocket.disconnect();
	// 	if (message) this.webSocket.emit('roboDisconnect', message);
	// }

	// getDadosSubscription(robo: Robo): Observable<unknown>
	// {
	// 	// this.connectSocket(robo.id?.toString()??'all');
	// 	const wse = this.webSocket.fromEvent(`leitura-${robo.id}`);
	// 	return wse;
	// }

	private async getHash(dispositivo: string, tempo: Date): Promise<string>
	{
		// if (!tempo) throw new HttpException('É necessário informar a data da leitura!', HttpStatus.BAD_REQUEST);
		const myHash = `${dispositivo}-${environment.HASH_KEY}-${tempo.toISOString()}`;
		let preHash = new TextEncoder().encode(myHash);
		let byteHash = (await window.crypto.subtle?.digest('SHA-256', preHash));
		const arrayHash = Array.from(new Uint8Array(byteHash));
		const hexHash = arrayHash
			.map(b => b.toString(16).padStart(2, '0'))
			.join('')
		// .toLocaleUpperCase();
		return hexHash;
	}
}
