<div class="grid">
	<ng-container *ngFor="let sala of salas">
		<div class="col">
			<div [class]="'sala sala-'+sala.id" [class.atencao]="atencao(sala)">
				<div class="info">
					<div class="unidade">{{sala.label}}</div>
					<div class="endereco">{{getLocalizacao(sala)}}</div>
				</div>
				<div class="robos">
					<stj-sust-robo-luz [robo]="(sala.roboLuz)!" (onAction)="onClick(sala)"></stj-sust-robo-luz>
					<!-- <stj-sust-robo-luz [leitura]="(sala.roboLuz?.ultimaLeitura)!" (onAction)="onClick(sala)"></stj-sust-robo-luz> -->
					<ng-container *ngFor="let robo of (Object.values(sala.robosJanela??{})??[])">
						<stj-sust-robo-janela [robo]="robo" (onAction)="onClick(sala)"></stj-sust-robo-janela>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</div>