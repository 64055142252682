<form [formGroup]="formGroup" class="componente">
	<p-button icon="pi pi-chevron-left" [rounded]="true" [text]="true" *ngIf="!disableButtons" (click)="passaData(-1)" [disabled]="disabledPrev" />
	<p-calendar
		styleClass="campo-data"
		formControlName="date"
		[minDate]="minDate"
		[maxDate]="maxDate"
		dateFormat="dd/mm/yy"
	/>
	<p-button icon="pi pi-chevron-right" [rounded]="true" [text]="true" *ngIf="!disableButtons" (click)="passaData(1)" [disabled]="disabledNext" />
</form>