import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { authGuard } from 'src/app/modules/auth/guards/auth.guard';
import { CustomMenuItem } from '../layout/services/menu.service';
import { SustentabilidadeHistoricoPageComponent } from './components/sustentabilidade-historico-page/sustentabilidade-historico-page.component';
import { SustentabilidadePredioPageComponent } from './components/sustentabilidade-predio-page/sustentabilidade-predio-page.component';
import { SustentabilidadeRoboAdminComponent } from './components/sustentabilidade-robo-admin/sustentabilidade-robo-admin.component';
import { SustentabilidadeSobrePageComponent } from './components/sustentabilidade-sobre-page/sustentabilidade-sobre-page.component';
import { SustDetalheRoboComponent } from 'src/app/modules/sustentabilidade/components/sust-detalhe-sensor/sust-detalhe-sensor.component';

export const moduleRoutes: Route[] = [
	{ path: '', pathMatch: 'full', redirectTo: 'predio' },
	{ path: 'sobre', component: SustentabilidadeSobrePageComponent, canActivate: [authGuard] },
	{ path: 'area', component: SustentabilidadePredioPageComponent, canActivate: [authGuard] },
	{ path: 'historico', component: SustentabilidadeHistoricoPageComponent, canActivate: [authGuard] },
	{ path: 'robos', component: SustentabilidadeRoboAdminComponent, canActivate: [authGuard] },
	// { path: 'robos', component: SustentabilidadeRoboAdminComponent, canActivate: [roleGuard], data: { roles: ['admin.all', 'sustentabilidade.admin'] } },
	{ path: 'robo/:idRobo', component: SustDetalheRoboComponent },
];

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	]
})
export class SustentabilidadeModule
{
	static menuItems:CustomMenuItem[] = [
		{
			label: 'Sobre o projeto',
			icon: 'pi pi-fw pi-book',
			routerLink: ['/home'],
		},
		{
			label: 'Econobots',
			icon: 'pi pi-fw pi-android',
			items: [
				{
					label: 'Visão por áreas',
					icon: 'pi pi-fw pi-building',
					routerLink: ['/sustentabilidade', 'area'],
				},
				{
					label: 'Histórico de medidas',
					icon: 'pi pi-fw pi-chart-line',
					routerLink: ['/sustentabilidade', 'historico'],
				},
			],
		},
		// {
		// 	label: 'Sustentabilidade',
		// 	icon: 'pi pi-fw pi-bolt',
		// 	items: [
		// 	],
		// },
	];

	static adminMenuItems:MenuItem[] = [
		{
			label: 'Gerenciar robôs',
			icon: 'pi pi-fw pi-android',
			routerLink: ['/sustentabilidade', 'robos'],
		},
	]
}
