import { BehaviorSubject } from "rxjs";
import { Unidade } from "src/app/types/servidor.types";

export const SUST_ROBO_TIPO = {
	'0': "Sensores integrados (temp., luz, umidade, presença)",
	'1': "Sensor de abertura de janela",
}

export interface tRobo
{
	id?: number;
	dispositivo?: string;
	ip?: string;
	id_unidade?: number;
	tipo?: string;
	dtLastView?: Date;
	createdDate?: Date;
	updatedDate?: Date;
	deletedDate?: Date | null;
	leituras?: Leitura[];
	ultimaLeitura?: Leitura;
	isPresente?: boolean | null;
	isAcesa?: boolean | null;
}

export interface tLeitura
{
	id?: number;
	dispositivo?: string;
	ip?: string;
	luminosidade?: number | null;
	umidade?: number | null;
	temperatura?: number | null;
	presente?: boolean | null;
	// acesa?: boolean;
	tipo?: '0' | '1';
	dtLastView?: Date;
	luzLimite?: number;
	janelaAberta?: boolean | null;
	siglaUnidade?: string;
	robo?: Robo;
	minute?: number;
	hour?: number;
	day?: number;
	month?: number;
	year?: number;
}

export class Robo implements tRobo
{
	id?: number;
	dispositivo?: string;
	ip?: string;
	id_unidade?: number;
	tipo?: string;
	dtLastView?: Date;
	createdDate?: Date;
	updatedDate?: Date;
	deletedDate?: Date | null;
	leituras?: Leitura[];
	// ultimaLeitura?: Leitura | undefined;

	constructor(args?: Partial<tRobo>)
	{
		for (let i in args)
		{
			(this as any)[i] = (args as any)[i];
		}
	}

	get isAcesa(): boolean | undefined
	{
		let leitura = this.ultimaLeitura;
		// console.log(leitura)
		if (!leitura) return undefined;
		return (leitura.luminosidade ?? 0) >= (leitura.luzLimite ?? 500);
	}

	get isPresente(): boolean | undefined | null
	{
		let leitura = this.ultimaLeitura;
		if (!leitura) return undefined;
		return (leitura.presente);
	}

	get ultimaLeitura(): Leitura | undefined
	{
		// ultimaLeitura?(): Leitura | undefined {
		let leitura = [...(this.leituras ?? [])].pop();
		if (!leitura) return undefined;
		return leitura;
	}
}

export class Leitura implements tLeitura
{
	id?: number;
	dispositivo?: string;
	ip?: string;
	mac?: string;
	luminosidade?: number | null;
	umidade?: number | null;
	temperatura?: number | null;
	presente?: boolean | null;
	tipo?: '0' | '1';
	// acesa?: boolean;
	luzLimite?: number = 500;
	janelaAberta?: boolean | null;
	statusJanelas?: string | any;
	qtJanelas?: number;
	dtLastView?: Date | undefined;
	// siglaUnidade?: string;
	robo?: Robo;
	minute?: number;
	hour?: number;
	day?: number;
	month?: number;
	year?: number;
	unidade?: Unidade;

	constructor(args?: tLeitura)
	{
		for (let i in args)
		{
			(this as any)[i] = (args as any)[i];
		}
	}

	isAcesa?(): boolean
	{
		return (this.luminosidade ?? 0) >= (this.luzLimite ?? 500);
	}

	// get time():Date
	// {
	// 	return new Date();
	// }

}

export type Robos = {
	[key: number]: Robo;
}

export type Sala = {
	id: number;
	label?: string;
	left?: number;
	top?: number;
	width?: number;
	height?: number;
	roboLuz?: Robo;
	robosJanela?: { [key: number]: Robo };
	leiturasRobos?: { [key: number]: BehaviorSubject<Leitura> };
	unidade?: Unidade;
	dados?: any;
}

export type Salas = {
	[key: string]: Sala;
}
