import { Component } from '@angular/core';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';

@Component({
	selector: 'stj-sustentabilidade-sobre-page',
	standalone: true,
	imports: [],
	providers: [
		MySharedModule,
	],
	templateUrl: './sustentabilidade-sobre-page.component.html',
	styleUrl: './sustentabilidade-sobre-page.component.scss'
})
export class SustentabilidadeSobrePageComponent
{

}
