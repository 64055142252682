
<p-button
	*ngIf="robo"
	[icon]="icon"
	[class.aceso]="aceso"
	styleClass="button-round"
	(onClick)="clicaBotao()">
</p-button>
<div class="presenca" *ngIf="presenca || false">
	<i class="pi pi-users"></i>
</div>