import { DatePipe } from '@angular/common';
import { AfterContentInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscriptions } from '../../../../types/system.types';
import { MySharedModule } from '../../../shared/my-shared.module';
import { SustRoboService } from '../../services/sust-robo.service';
import { Leitura } from '../../services/sust-robo.type';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'stj-sust-detalhe-sensor',
	standalone: true,
	imports: [
		MySharedModule,
		DatePipe,
	],
	providers: [
		DialogService,
		// DynamicDialogRef,
		SustRoboService,
		// DynamicDialogConfig,
	],
	templateUrl: './sust-detalhe-sensor.component.html',
	styleUrl: './sust-detalhe-sensor.component.scss'
})
export class SustDetalheRoboComponent implements AfterContentInit, OnDestroy
{
	constructor(
		private roboService: SustRoboService,
		// private dialogService: DialogService,
		private ref: DynamicDialogRef,
		private config: DynamicDialogConfig,
		private readonly aroute: ActivatedRoute,
	)
	{
		// this.instance = this.dialogService.getInstance(this.ref);
		this.maxLength = this.roboService.maxLength;
	}

	@ViewChild('graficoTemperatura') graficoTemperatura: any;
	@ViewChild('graficoLuminosidade') graficoLuminosidade: any;
	@ViewChild('graficoJanelas') graficoJanelas: any;
	graficoTemperaturaData?: ChartData;
	graficoLuminosidadeData?: ChartData;
	graficoJanelasData?: ChartData;
	graficoOptions?: ChartOptions = {};

	// sensor: BehaviorSubject<Robo> = new BehaviorSubject({} as Robo);
	robo?: Leitura;
	subs: Subscriptions = {};
	Math = Math;
	now: Date = new Date();
	maxLength: number;
	lastDate?: Date;
	timeOut?: any;
	// roboS: BehaviorSubject<Leitura> = new BehaviorSubject({} as Leitura);
	luminosidade?: number;
	janelaLabels: string[] = [];

	async ngAfterContentInit()
	{
		let robo = this.config?.data?.sensor;

		if (!robo)
		{
			let idRobo = this.aroute.snapshot.params['idRobo'];
			robo = await this.roboService.getRobo(idRobo);
			this.config.data = {
				sensor: robo
			}
		}

		if (robo)
		{

			// this.subs['dados'] = this.roboS.subscribe((robo: Leitura) =>
			// {
			// 	if (robo.id)
			// 	{
			// 		this.robo = robo;
			// 		this.montaGraficoTemperatura(this.robo!);
			// 		this.montaGraficoLuminosidade(this.robo!);
			// 		this.montaGraficoJanelas(this.robo!);
			// 		// console.log(sensor);
			// 	}
			// });

			this.pegaDados(robo);

			this.robo = robo;
		}

		this.initGraficOptions();
	}

	async pegaDados(robo: Leitura, fromDate?: Date)
	{
		// if (this.timeOut) clearTimeout(this.timeOut);
		if (!robo) return;
		let dados = await this.roboService.getDados(robo.id, fromDate);
		// console.log(dados);

		if (dados.length)
		{
			let lastItem = [...dados].pop();
			this.lastDate = lastItem?.dtLastView;
			let items: any[] = [];
			for (let dado of dados)
			{
				let r: Leitura = { ...robo, ...dado };
				items.push(r);
				// this.roboS.next(r);
			}
			this.robo = robo;
			this.montaGraficoLuminosidade(items);
			this.montaGraficoTemperatura(items);
			this.montaGraficoJanelas(items);
		}
		if (this.graficoLuminosidade)
		{
			this.graficoLuminosidade.chart?.update();
		}

		this.timeOut = setTimeout(async () =>
		{
			// console.log('timeout', dados?.length);
			this.pegaDados(robo, this.lastDate);
		},
			dados?.length ? 1000 : 30000,
		);
		return dados;
	}

	ngOnDestroy(): void
	{
		for (let s in this.subs) this.subs[s].unsubscribe();
		if (this.timeOut) clearInterval(this.timeOut);
		// this.roboService.disconnectSocket(this.config?.data?.sensor?.id);
	}

	private initGraficOptions()
	{
		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
		const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

		this.graficoOptions = {
			// stacked: false,
			maintainAspectRatio: false,
			aspectRatio: 0.6,
			plugins: {
				legend: {
					labels: {
						color: textColor
					}
				}
			},
			scales: {
				x: {
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder
					}
				},
				y: {
					type: 'linear',
					display: true,
					position: 'left',
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder
					},
					min: 0,
				},
				y0: {
					type: 'linear',
					display: 'auto',
					position: 'right',
					ticks: {
						color: textColorSecondary
					},
					grid: {
						drawOnChartArea: false,
						color: surfaceBorder
					},
					min: 0,
					max: 100,
				},
				y1: {
					type: 'linear',
					display: 'auto',
					position: 'right',
					ticks: {
						color: textColorSecondary
					},
					grid: {
						drawOnChartArea: false,
						color: surfaceBorder
					},
					min: 0,
				},
			}
		};
	}

	montaGraficoTemperatura(items: Leitura[])
	{
		let grafico = this.graficoTemperaturaData;
		if (!grafico)
		{
			grafico = {
				labels: [],
				datasets: [
					{
						label: "Temperatura da Sala",
						fill: false,
						// yAxisID: sala.id,
						tension: 0.3,
						data: [],
					}
				]
			};

			grafico.datasets.push({
				label: 'Umidade',
				yAxisID: 'y0',
				fill: false,
				tension: 0.3,
				// borderDash: [5, 5],
				data: [],
			});
		}
		for (let item of items)
		{
			let dt: Date = item.dtLastView ? new Date(item.dtLastView) : new Date();
			dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
			grafico.labels?.push(dt.toLocaleString('short'));
			if ((grafico.labels?.length ?? 0) > this.maxLength)
			{
				grafico.labels?.shift();
			}
			// console.log(dt.toLocaleTimeString());
			let i = 0;
			// for (let sala of this.salas)
			// {
			if (!item) return;
			let sensorLuz: Leitura = item;
			if (sensorLuz.temperatura) grafico.datasets[0].data.push(sensorLuz.temperatura ?? 0);

			let umidade = item.umidade ? Math.round(Number(item.umidade)) : null;
			grafico.datasets[1].data.push(umidade);
		}

		for (let ds of grafico.datasets)
		{
			if ((ds.data?.length ?? 0) > this.maxLength)
			{
				ds.data?.shift();
			}
		}
		// 	i++;
		// }
		this.graficoTemperaturaData = grafico;
		// this.cdr.markForCheck();
		// console.log(grafico);
		// this.cdr.detectChanges();
		if (this.graficoTemperatura)
		{
			this.graficoTemperatura.chart?.update();
		}
	}

	montaGraficoLuminosidade(items: Leitura[])
	{
		let grafico = this.graficoLuminosidadeData;
		if (!grafico)
		{
			grafico = {
				labels: [],
				datasets: [
					{
						label: 'Limite para Luzes acesas',
						fill: false,
						tension: 0.3,
						borderDash: [5, 5],
						data: [],
					},
					{
						label: 'Luminosidade',
						fill: false,
						tension: 0.3,
						// borderDash: [5, 5],
						data: [],
					},
					{
						label: 'Presença',
						yAxisID: 'y1',
						fill: true,
						tension: 0.0,
						// borderDash: [5, 5],
						data: [],
					},
				]
			};
		}

		for (let item of items)
		{
			if (!item) return;
			let dt: Date = item.dtLastView ? new Date(item.dtLastView) : new Date();
			let dtStr: string = dt.toLocaleString('short', { timeZone: 'America/Sao_paulo' });
			grafico.labels?.push(dtStr);

			let i = 1;
			let luminosidade = this.roboService.getLuminosidadePerc(item?.luminosidade ?? undefined);
			this.luminosidade = luminosidade ?? undefined;
			
			let presenca = item.presente ? 1 : 0;
			grafico.datasets[0].data.push(item.luzLimite??30);
			grafico.datasets[1].data.push(luminosidade);
			grafico.datasets[2].data.push(presenca);
			// console.log(item.dtLastView?.toLocaleString(), luminosidade, presenca, item.luzLimite);
			
			// for (let sala of this.salas)
			// {
			// 	// console.log(sala.id)
			// 	let sensorLuz: Robo = sala.sensorLuz.value;
			// 	grafico.datasets[i].data.push(sensorLuz.luminosidade ?? 0);
			// 	i++;
			// }
		}


		// console.log(sensor);

		if ((grafico.labels?.length ?? 0) > this.maxLength)
		{
			grafico.labels?.shift();
		}
		for (let ds of grafico.datasets)
		{
			if ((ds.data?.length ?? 0) > this.maxLength)
			{
				ds.data?.shift();
			}
		}
		this.graficoLuminosidadeData = grafico;

		if (this.graficoLuminosidade)
		{
			this.graficoLuminosidade.chart?.update();
		}
	}

	montaGraficoJanelas(items: Leitura[])
	{
		let grafico = this.graficoJanelasData;
		if (!grafico)
		{
			grafico = {
				labels: [],
				datasets: []
			};
		}
		for (let item of items)
		{
			let statusJanelas: any = item.statusJanelas;
			if (statusJanelas) statusJanelas = JSON.parse(statusJanelas);
			if (!statusJanelas) return;

			for (let janela of statusJanelas)
			{
				if (!this.janelaLabels.includes(janela.label))
				{
					grafico.datasets.push({
						label: `${janela.label}`,
						fill: true,
						// yAxisID: 'y1',
						tension: 0.3,
						// borderDash: [5, 5],
						data: [],
					});
					this.janelaLabels.push(janela.label);
				}
			}
			let dt: Date = item.dtLastView ? new Date(item.dtLastView) : new Date();
	
			grafico.labels?.push(dt.toLocaleTimeString());
			// console.log(sensor);
			if ((grafico.labels?.length ?? 0) > this.maxLength)
			{
				grafico.labels?.shift();
			}
			if (!item) return;
	
			// console.log(statusJanelas);
			for (let j in statusJanelas)
			{
				let janela = statusJanelas[j];
				let p = this.janelaLabels.findIndex((label) => label == statusJanelas[j].label)
				grafico.datasets[Number(p)].data.push(Number(janela.status));
			}
		}

		for (let ds of grafico.datasets)
		{
			if ((ds.data?.length ?? 0) > this.maxLength)
			{
				ds.data?.shift();
			}
		}
		this.graficoJanelasData = grafico;

		if (this.graficoJanelas)
		{
			this.graficoJanelas.chart?.update();
		}
	}
}
