import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { Subscriptions } from '../../../../types/system.types';
import { MySharedModule } from '../../../shared/my-shared.module';
import { SustRoboService } from '../../services/sust-robo.service';
import { Leitura, Robo } from '../../services/sust-robo.type';
import { SustDetalheRoboComponent } from '../sust-detalhe-sensor/sust-detalhe-sensor.component';
import { Router } from '@angular/router';

@Component({
	selector: 'stj-sust-robo-janela',
	standalone: true,
	imports: [
		CommonModule,
		MySharedModule,
	],
	providers: [
		DialogService,
		DynamicDialogRef,
		SustRoboService,
	],
	templateUrl: './sust-robo-janela.component.html',
	styleUrl: './sust-robo-janela.component.scss'
})
export class SustRoboJanelaComponent implements AfterContentInit, OnDestroy, OnChanges
{
	constructor(
		public dialogService: DialogService,
		// public ref: DynamicDialogRef,
		private roboService: SustRoboService,
		private readonly router: Router,
	) {}
	
	ref: DynamicDialogRef | undefined;
	icon: string = 'pi pi-moon';
	@Output('onAction') onAction: EventEmitter<string> = new EventEmitter();

	@Input('robo') robo?: Robo;

	public set janelaAberta(value: boolean)
	{
		this._janelaAberta = value;
		this.icon = `pi pi-${value ? 'lock-open' : 'lock'}`;
	}
	private _janelaAberta: boolean = false;
	public get janelaAberta(): boolean
	{
		return this._janelaAberta;
	}
	presenca: boolean = false;

	leitura?: Leitura;
	subs: Subscriptions = {};
	interval?: any;
	leiturasBS: BehaviorSubject<Leitura> = new BehaviorSubject((this.robo?.ultimaLeitura??{}) as Leitura);
	lastDate?: Date;

	clicaBotao()
	{
		let args: DynamicDialogConfig = {
			header: `Detalhes do Robo '${this.robo?.dispositivo}'`,
			position: 'right',
			modal: true,
			closeOnEscape: true,
			width: '50vw',
			height: '99vh',
			breakpoints: {
				'960px': '75vw',
				'640px': '90vw'
			},
			data: {
				sensor: this.robo,
			}
		};
		this.ref = this.dialogService.open(SustDetalheRoboComponent, args);
		this.onAction.emit('click');

		// this.router.navigate(['/sustentabilidade', 'robo', this.robo?.id]);
	}

	ngAfterContentInit(): void
	{
		if(!this.robo) return;
		this.subs['robo'] = this.leiturasBS?.subscribe(leitura =>
		{
			if (!leitura) return;
			this.leitura = leitura;
		});

		let leitura: Leitura | undefined;
		leitura = this.robo?.ultimaLeitura;
		if (leitura) this.leiturasBS.next(leitura);
		// this.atualizaDados();
	}
	
	ngOnChanges(changes: SimpleChanges): void
	{
		let leitura: Leitura | undefined;
		leitura = this.robo?.ultimaLeitura;
		if (!leitura) return;
		if (leitura) this.leiturasBS.next(leitura);
		// console.log(leitura.janelaAberta, leitura.id);
		// const luminosidade = leitura?.luminosidade ?? 0;
		// const luzLimite: number = leitura.luzLimite??550;
		// this.aceso = luminosidade >= luzLimite;
		// this.presenca = this.robo?.isPresente!() ?? false;
		// // console.log(luminosidade, this.aceso);
		this.janelaAberta = !!leitura.janelaAberta;
		// console.log(leitura)
	}

	ngOnDestroy(): void
	{
		for (let s in this.subs)
		{
			if (this.subs[s]) this.subs[s]?.unsubscribe();
		}
	}
	
	async atualizaDados()
	{
		let dados = await this.roboService.getDados(this.robo?.id, this.lastDate);
		// console.log(dados);
		for (let dado of dados)
		{
			const luminosidade = dado?.luminosidade ?? 0;
			this.janelaAberta = luminosidade >= (dado?.luzLimite??500);
			this.presenca = dado?.presente ?? false;
			this.lastDate = dado.dtLastView;
			this.leiturasBS.next(dado);
		}
		// console.log(luminosidade, this.aceso);
		this.interval = setTimeout(() => {
			this.atualizaDados();
		},5000);
	}

}
