import { DatePipe } from '@angular/common';
import { AfterContentInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LeiturasPorUnidade } from 'src/app/areas/sustentabilidade/components/sustentabilidade-predio-page/sustentabilidade-predio-page.component';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';
import { Subscriptions } from 'src/app/types/system.types';
import { SustRoboService } from '../../services/sust-robo.service';
import { Leitura } from '../../services/sust-robo.type';

@Component({
	selector: 'stj-sust-graficos-dia',
	standalone: true,
	imports: [
		MySharedModule,
		DatePipe,
	],
	providers: [
		DialogService,
		SustRoboService,
	],
	templateUrl: './sust-graficos-dia.component.html',
	styleUrl: './sust-graficos-dia.component.scss'
})
export class SustGraficosDiaComponent implements AfterContentInit, OnDestroy
{
	constructor(
		private roboService: SustRoboService,
		// private dialogService: DialogService,
		private ref: DynamicDialogRef,
		private config: DynamicDialogConfig,
	)
	{
		// this.instance = this.dialogService.getInstance(this.ref);
		// this.maxLength = this.roboService.maxLength;
	}

	@ViewChild('graficoTemperatura') graficoTemperatura: any;
	@ViewChild('graficoLuminosidade') graficoLuminosidade: any;

	graficoTemperaturaData?: ChartData;
	graficoLuminosidadeData?: ChartData;
	graficoOptions?: ChartOptions = {};

	// sensor: BehaviorSubject<Robo> = new BehaviorSubject({} as Robo);
	// robo?: Leitura;
	subs: Subscriptions = {};
	Math = Math;
	now: Date = new Date();
	// maxLength: number;
	lastDate?: Date;
	interval?: any;

	ngAfterContentInit(): void
	{
		this.initGraficOptions();
		this.montaGraficos(this.config.data.sensor);
	}

	ngOnDestroy(): void
	{
		for (let s in this.subs) this.subs[s].unsubscribe();
		if (this.interval) clearInterval(this.interval);
	}

	private initGraficOptions()
	{
		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
		const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

		this.graficoOptions = {
			// stacked: false,
			maintainAspectRatio: false,
			aspectRatio: 0.6,
			plugins: {
				legend: {
					labels: {
						color: textColor
					}
				}
			},
			scales: {
				x: {
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder
					}
				},
				y: {
					type: 'linear',
					display: true,
					position: 'left',
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder
					}
				},
				y1: {
					type: 'linear',
					display: true,
					position: 'right',
					suggestedMin: 0,
					suggestedMax: 1,
					ticks: {
						color: textColorSecondary
					},
					grid: {
						drawOnChartArea: false,
						color: surfaceBorder
					}
				}
			}
		};
	}

	private montaGraficos(leiturasParcial: any)
	{
		if (Object.keys(leiturasParcial).length == 0) return;
		// console.log(leiturasParcial);

		this.montaGraficoTemperatura(leiturasParcial['items']);
		this.montaGraficoLuminosidade(leiturasParcial['items']);

		// this.leituras = { ...this.leituras, ...leiturasParcial };
	}

	montaGraficoTemperatura(leiturasParcial: any)
	{
		// console.log(leiturasParcial);
		let grafico = this.graficoTemperaturaData;

		if (!grafico)
		{
			grafico = {
				labels: [],
				datasets: []
			};
			let j = 0;

			let n = Number(j) * 2;

			grafico.datasets[n] = {
				label: `Temperatura`,
				fill: false,
				yAxisID: 'y',
				// tension: 0.3,
				data: [],
			};
			grafico.datasets[n + 1] = {
				label: `Janela(s) Aberta(s)`,
				fill: true,
				borderDash: [5, 5],
				yAxisID: 'y1',
				// tension: 0.3,
				data: [],
			};
		}

		let lastLeitura;
		for (let ix in leiturasParcial)
		{
			let leitura: Leitura | any = leiturasParcial[ix];
			// console.log(leitura)
			let dtx = new Date(leitura.dtLastView)
			let dt = new Date();
			dt.setTime(Number(dtx));
			dt.setSeconds(0, 0);

			// Datas
			// let dtx: Date = this.montaData(leitura);
			grafico.labels?.push((new Date(dt)).toLocaleString());

			// for (let id_unidade in this.salas)
			{
				// 				let i = Object.keys(this.salas).findIndex(key => key == leitura.robo?.id_unidade?.toString());
				let n = 0;
				if (!leitura)
				{
					let data = grafico.datasets[n].data
					let lastEntry = data.length ? data[data.length - 1] : undefined;

					leitura = {
						minute: lastLeitura?.minute,
						hour: lastLeitura?.hour,
						day: lastLeitura?.day,
						month: lastLeitura?.month,
						year: lastLeitura?.year,
					};
					if (lastLeitura?.temperatura !== undefined) leitura.temperatura = Number(lastEntry) || 0;
					// leitura = { ...lastLeitura };
				}
				else
				{
					lastLeitura = leitura;
				}

				// Temperatura
				grafico.datasets[n]?.data.push(leitura.temperatura ?? undefined);

				// Janelas
				let valorJanelas: number | null;
				if (leitura.janelaAberta !== undefined) valorJanelas = (leitura.janelaAberta ? 1 : 0); else valorJanelas = null;

				grafico.datasets[n + 1]?.data.push(valorJanelas);
			}
		}

		// let i = this.salas.findIndex(sala => sala.label == leitura.siglaUnidade);

		this.graficoTemperaturaData = grafico;

		if (this.graficoTemperatura)
		{
			this.graficoTemperatura.chart?.update();
		}
	}

	montaGraficoLuminosidade(leiturasParcial: any)
	{
		// console.log(leiturasParcial);
		let grafico = this.graficoLuminosidadeData;
		if (!grafico)
		{
			grafico = {
				labels: [],
				datasets: []
			};

			grafico.datasets[0] = {
				label: 'Luminosidade',
				fill: false,
				yAxisID: 'y',
				// tension: 0.3,
				data: [],
			};
			grafico.datasets[1] = {
				label: `Presença na sala`,
				fill: true,
				yAxisID: 'y1',
				borderDash: [5, 5],
				// tension: 0.3,
				data: [],
			};
			grafico.datasets.push({
				label: 'Limite para Luzes acesas',
				fill: false,
				yAxisID: 'y',
				tension: 0.3,
				borderDash: [5, 5],
				data: [],
			});
		}

		for (let ix in leiturasParcial)
		{
			let leitura: any = leiturasParcial[ix];

			if (leitura.luminosidade === null) continue;

			let dtx = new Date(leitura.dtLastView)
			let dt = new Date();
			dt.setTime(Number(dtx.getTime()));
			dt.setSeconds(0, 0);

			grafico.labels?.push((dt).toLocaleString());

			// Luz limite no último dataset
			let j = grafico.datasets.length - 1;
			// let luzLimite = Math.round(((leitura.luzLimite ?? 550) / 1024) * 100);
			let luzLimite = leitura.luzLimite;
			// console.log(luzLimite);
			// let luzLimite = Math.round(((400) / 1024) * 100);
			grafico.datasets[j]?.data.push(luzLimite);

			let i = 0;
			// for (let id_unidade in this.salas)
			{
				
				// i++;
				let n = Number(i) * 2;
				// Luminosidade
				let luminosidade: number | null = null;
				// let luzLeitura = Number(leitura.luminosidade) ?? 1024;
				// if (isNaN(luzLeitura)) luzLeitura = 1024;
				// luminosidade = Math.round(((luzLeitura) / 4500) * 1000) / 10;
				luminosidade = this.roboService.getLuminosidadePerc(leitura.luminosidade);
				grafico.datasets[n]?.data.push(luminosidade);

				// Presença
				grafico.datasets[n + 1]?.data.push(leitura.presente === null ? null : (leitura.presente ? 1 : 0));
			}
		}

		this.graficoLuminosidadeData = grafico;

		if (this.graficoLuminosidade)
		{
			this.graficoLuminosidade.chart?.update();
		}
	}
}
