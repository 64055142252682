<p-panel styleClass="m-3">
	<p-header>
		<h2 class="p-panel-title">Visão geral dos sensores</h2>
	</p-header>
	<ng-template pTemplate="icons">
		<div class="panel-opcoes">
			<stj-unidade-btn-select-tree [unidadeId]="selectedUnidadeId" (ngModelChange)="onSelectUnidade($event)"></stj-unidade-btn-select-tree>
			<!-- <p-dropdown [options]="predios" [ngModel]="selectedPredio" (ngModelChange)="onSelectPredio($event)" placeholder="Selecione um bloco/edifício" optionLabel="label" [showClear]="true"></p-dropdown>
			<p-dropdown [options]="corredores" [(ngModel)]="selectedCorredor" placeholder="Selecione um corredor/setor" optionLabel="label" [showClear]="true"></p-dropdown> -->
		</div>
		<button title="Recarregar dados" pButton class="p-panel-header-icon" (click)="refresh()">
			<span class="pi pi-refresh"></span>
		</button>
	</ng-template>
	<ng-container #body>
		<p-accordion [activeIndex]="[0,1,2]" [multiple]="true" styleClass="my-3">
			<!-- <p-accordionTab header="Planta baixa do setor">
				<stj-sust-planta-baixa></stj-sust-planta-baixa>
			</p-accordionTab> -->
			<p-accordionTab header="Salas no setor">
				<stj-sust-salas [salas]="salasArr"></stj-sust-salas>
				<p-progressBar *ngIf="loading" mode="indeterminate" [style]="{ height: '6px' }" />
			</p-accordionTab>
			<p-accordionTab header="Temperaturas">
				<div class="card" *ngIf="!loading && !loadingDados">
					<p-chart type="line" #graficoTemperatura [data]="graficoTemperaturaData" [options]="graficoOptions" [height]="'300px'"></p-chart>
				</div>
				<p-progressBar *ngIf="loading || loadingDados" mode="indeterminate" [style]="{ height: '6px' }" />
			</p-accordionTab>
			<p-accordionTab header="Luminosidade">
				<div class="card" *ngIf="!loading && !loadingDados">
					<p-chart type="line" #graficoLuminosidade [data]="graficoLuminosidadeData" [options]="graficoOptions" [height]="'300px'"></p-chart>
				</div>
				<p-progressBar *ngIf="loading || loadingDados" mode="indeterminate" [style]="{ height: '6px' }" />
			</p-accordionTab>
		</p-accordion>
	</ng-container>
</p-panel>
