<p-accordion [activeIndex]="[0,1]" [multiple]="true" styleClass="my-3">
	<p-accordionTab header="Temperaturas">
		<div class="card">
			<p-chart type="line" #graficoTemperatura [data]="graficoTemperaturaData" [options]="graficoOptions"
				[height]="'300px'"></p-chart>
		</div>
	</p-accordionTab>
	<p-accordionTab header="Luminosidade">
		<div class="card">
			<p-chart type="line" #graficoLuminosidade [data]="graficoLuminosidadeData" [options]="graficoOptions"
				[height]="'300px'"></p-chart>
		</div>
	</p-accordionTab>
</p-accordion>
