<p-contextMenu #cm [model]="cmItems"></p-contextMenu>
<p-panel styleClass="m-3">
	<p-header>
		<h2 class="p-panel-title">Visão geral dos sensores</h2>
	</p-header>
	<ng-template pTemplate="icons">
		<div class="panel-opcoes">
			<stj-unidade-btn-select-tree (ngModelChange)="onSelectUnidade($event)"></stj-unidade-btn-select-tree>
		</div>
		<button title="Recarregar dados" pButton class="p-panel-header-icon" (click)="refresh()">
			<span class="pi pi-refresh"></span>
		</button>
	</ng-template>
	<ng-container #body>
		<p-table
			[value]="items"
			styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
			[paginator]="true"
			[rows]="20"
			[totalRecords]="total"
			[columns]="colunas"
			[(contextMenuSelection)]="selectedItem"
			[contextMenu]="cm"
			[(selection)]="selectedItem"
			[loading]="loading">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns" [class]="'text-'+col.align">
						{{col.header}}
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item let-columns="columns">
				<tr [pSelectableRow]="item" [pContextMenuRow]="item">
					<td *ngFor="let col of columns" [class]="'text-'+col.align">
						{{ pegaValor(item,col.field) }}
					</td>
				</tr>
			</ng-template>
		</p-table>
	</ng-container>
</p-panel>
