import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { SustRoboService } from '../../services/sust-robo.service';
import { Robo, Sala } from '../../services/sust-robo.type';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';
import { SustSalaComponent } from '../sust-sala/sust-sala.component';
import { SustRoboJanelaComponent } from '../sust-robo-janela/sust-robo-janela.component';
import { SustRoboLuzComponent } from '../sust-robo-luz/sust-robo-luz.component';

@Component({
	selector: 'stj-sust-salas',
	standalone: true,
	imports: [
		MySharedModule,
		SustSalaComponent,
		SustRoboJanelaComponent,
		SustRoboLuzComponent
	],
	providers: [
		SustRoboService,
	],
	templateUrl: './sust-salas.component.html',
	styleUrl: './sust-salas.component.scss'
})
export class SustSalasComponent implements OnInit, OnChanges
{
	constructor(
		private sensorService: SustRoboService,
	)
	{

	}

	@Input('salas')
	inputSalas: Sala[] = [];

	salas: Sala[] = [];
	sub?: Subscription;
	robosJanela: Robo[] = [];
	Object = Object;

	// @Input('salas') salas: BehaviorSubject<Salas> = new BehaviorSubject<Salas>({});

	ngOnInit(): void
	{
		// this.sub = this.sensorService.salas.subscribe(salas =>
		// {
		// 	this.salas = Object.values(salas);
		// });
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		// this.salas = [];
		// console.log('Change');
		let salas: Sala[] = [];
		// this.salas = this.inputSalas;
		for (let inputSala of this.inputSalas)
		{
			// let sala = {...inputSala};
			// if (sala.leiturasRobos) console.log(sala.leiturasRobos![1].value);
			// salas.push(sala);
			// console.log(inputSala.roboLuz)
			salas.push(inputSala);
		}
		this.salas = salas;
		// console.log(this.salas);
		// console.log(changes)
	}

	onClick(sala: Sala)
	{
		// console.log(sala);
	}


	atencao(sala: Sala)
	{
		const atencao: boolean | undefined = ((sala.roboLuz?.isAcesa ?? false) && !(sala.roboLuz?.isPresente ?? false));
		// console.log(this.sala?.id, atencao, this.robo?.isAcesa(), this.robo?.presenca)
		return atencao;
	}

	getLocalizacao(sala: Sala)
	{
		let saida: string = '';
		if (sala.dados)
		{ 
			saida = `${sala.dados.sala} (${sala.dados.predio} ${sala.dados.andar})`;
		}
		else
		{
			saida = sala.unidade?.localizacao ?? '';
		}
		return saida;
	}

}
