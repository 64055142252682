import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UnidadeBtnSelectTreeComponent } from 'src/app/modules/intranet/components/unidade-btn-select-tree/unidade-btn-select-tree.component';
import { DateSelectorComponent } from 'src/app/modules/shared/components/date-selector/date-selector.component';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';
import { MyMessageService } from 'src/app/modules/shared/services/my-message.service';
import { SustGraficosDiaComponent } from 'src/app/modules/sustentabilidade/components/sust-graficos-dia/sust-graficos-dia.component';
import { SustRoboService } from 'src/app/modules/sustentabilidade/services/sust-robo.service';
import { Unidade } from 'src/app/types/servidor.types';
import { Subscriptions } from 'src/app/types/system.types';

@Component({
	selector: 'stj-sustentabilidade-historico-page',
	standalone: true,
	imports: [
		MySharedModule,
		UnidadeBtnSelectTreeComponent,
		DateSelectorComponent,
		SustGraficosDiaComponent,
	],
	providers: [
		SustRoboService,
		DialogService,
	],
	templateUrl: './sustentabilidade-historico-page.component.html',
	styleUrl: './sustentabilidade-historico-page.component.scss'
})
export class SustentabilidadeHistoricoPageComponent implements OnInit, OnDestroy
{
	constructor(
		private roboService: SustRoboService,
		private msgService: MyMessageService,
		public dialogService: DialogService,
		@Inject(Router) private readonly router: Router,
		@Inject(ActivatedRoute) private readonly aroute: ActivatedRoute,
	)
	{
	}

	ref: DynamicDialogRef | undefined;
	loading: boolean = true;
	subs: Subscriptions = {};
	selectedUnidadeId?: number;
	selectedDate: Date = new Date();
	today: Date = new Date();
	items: any[] = [];
	loadingBtns: any = {};
	panelItem?: any;

	@ViewChild('op') overlayPanel?: OverlayPanel;

	ngOnInit(): void
	{
		this.subs['queryParams'] = this.aroute.queryParams.subscribe((params: any) =>
		{
			if (params.date) this.selectedDate = new Date(params.date);
			if (params.unidade) this.selectedUnidadeId = Number(params.unidade) || undefined;
			if (this.selectedDate || this.selectedUnidadeId)
			{
				this.carregaDados();
			}
			// console.log(this.selectedDate, this.selectedUnidadeId);
		})
	}

	ngOnDestroy(): void
	{
		for (let s in this.subs) this.subs[s].unsubscribe();
	}

	refresh()
	{
		this.carregaDados();
	}

	onSelectUnidade(unidade?: Unidade)
	{
		this.selectedUnidadeId = unidade?.id_unidade;
		this.router.navigate([], {
			relativeTo: this.aroute,
			queryParams: {
				date: this.selectedDate?.toISOString(),
				unidade: this.selectedUnidadeId,
			}
		});
		this.carregaDados();
	}

	onSelectDate(date?: Date)
	{
		this.selectedDate = date ?? new Date();
		this.router.navigate([], {
			relativeTo: this.aroute,
			queryParams: {
				date: this.selectedDate?.toISOString(),
				unidade: this.selectedUnidadeId,
			}
		});
		this.carregaDados()
	}

	async carregaDados()
	{
		if (!this.selectedUnidadeId) return;
		this.loading = true;
		this.items = [];
		try
		{
			let dados = await this.roboService.getHistoricoDia(this.selectedUnidadeId, this.selectedDate);
			this.items = Object.values(dados);
			// if (this.items[0]) this.mostraGraficos(this.items[0]);
			// console.log(this.items)
		} catch (error: any)
		{
			this.msgService.error(error);
		}
		this.loading = false;
	}

	formataMinutos(tempo: number): string
	{
		let minutos = Number(tempo);
		let saida: string = '';

		// Minutos
		if (minutos < 60) return minutos.toLocaleString('pt-BR', { maximumFractionDigits: 1 }) + 'min';

		// Horas
		let horas = Math.floor(minutos / 60);
		minutos = minutos % 60;
		if (horas <= 24) return `${horas}h${minutos.toLocaleString('pt-BR', { maximumFractionDigits: 0 })}min`;

		return saida;
	}

	async enviaTeams(id_unidade: number, dia: Date)
	{
		// console.log(id_unidade);
		this.loadingBtns[id_unidade] = true;
		try
		{
			let dados = await this.roboService.enviaHistoricoDia(id_unidade, dia);
			// console.log(dados);
		} catch (error: any)
		{
			this.msgService.error(error);
		}
		this.loadingBtns[id_unidade] = false;
	}

	async mostraGraficos(item: any)
	{
		let args: DynamicDialogConfig = {
			header: `Dados para a ${item.unidade?.sigla_unidade} - ${(this.selectedDate.toLocaleDateString('pt-BR')) ?? ''}`,
			position: 'right',
			modal: true,
			closeOnEscape: true,
			width: '80vw',
			height: '99vh',
			breakpoints: {
				'960px': '75vw',
				'640px': '90vw'
			},
			data: {
				sensor: item,
			}
		};
		this.ref = this.dialogService.open(SustGraficosDiaComponent, args);
	}

	async mostraEndereco(item: any, event: any)
	{
		this.overlayPanel?.hide();
		// this.panelItem = [];
		setTimeout(() => {
			this.panelItem = item;
			this.overlayPanel?.show(event, event.currentTarget);
		},150)
	}
}
