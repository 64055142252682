import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MySharedModule } from '../../my-shared.module';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';

@Component({
	selector: 'stj-date-selector',
	standalone: true,
	imports: [
		MySharedModule,
		FormsModule,
	],
	templateUrl: './date-selector.component.html',
	styleUrl: './date-selector.component.scss'
})
export class DateSelectorComponent implements OnInit, OnChanges
{
	constructor ()
	{
		this.formGroup = new FormGroup({
			date: new FormControl<Date | null>(null)
		});
	}

	@Input('date') selectedDate?: Date;
	@Input('maxDate') maxDate?: Date;
	@Input('minDate') minDate?: Date;
	@Input('disableButtons') disableButtons: boolean = false;
	@Output('dateChange') emitter: EventEmitter<Date> = new EventEmitter();

	disabledNext: boolean = false;
	disabledPrev: boolean = false;

	formGroup: FormGroup;

	ngOnInit(): void
	{
		if (!this.minDate)
		{
			this.minDate = new Date(2024,3,1,0,0,0,0);
		}
		this.minDate.setHours(0, 0, 0, 0);
		if (!this.maxDate)
		{
			this.maxDate = new Date();
		}
		this.maxDate.setHours(0, 0, 0, 0);
		if (this.selectedDate)
		{
			this.selectedDate.setHours(0, 0, 0, 0);
		}

		this.formGroup.valueChanges.subscribe(values =>
		{
			this.selectedDate = values.date;
			this.verificaDatas();
			this.emitter.emit(this.selectedDate);
		});
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		this.formGroup.setValue({ date: this.selectedDate });
		this.verificaDatas();
	}

	verificaDatas()
	{
		if (this.maxDate)
		{
			if (this.selectedDate) this.disabledNext = this.selectedDate?.getTime() >= this.maxDate?.getTime();
		}
		else
		{
			this.disabledNext = false;
		}
		if (this.minDate)
		{
			if (this.selectedDate) this.disabledPrev = this.selectedDate?.getTime() <= this.minDate?.getTime();
		}
		else
		{
			this.disabledPrev = false;
		}
	}

	passaData(direction: number)
	{
		if (!this.selectedDate) this.selectedDate = new Date();

		this.selectedDate.setDate(this.selectedDate.getDate() + direction);
		this.formGroup.setValue({ date: this.selectedDate });
		// console.log(direction, this.disabledPrev, this.disabledNext);
	}
}
