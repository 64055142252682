<p-panel styleClass="m-3">
	<p-header>
		<h2 class="p-panel-title">Histórico de medidas</h2>
	</p-header>
	<ng-template pTemplate="icons">
		<div class="panel-opcoes">
			<stj-unidade-btn-select-tree [unidadeId]="selectedUnidadeId"
				(ngModelChange)="onSelectUnidade($event)"></stj-unidade-btn-select-tree>
			<stj-date-selector [(date)]="selectedDate" (dateChange)="onSelectDate($event)"
				[maxDate]="today"></stj-date-selector>
		</div>
		<button title="Recarregar dados" pButton class="p-panel-header-icon" (click)="refresh()">
			<span class="pi pi-refresh"></span>
		</button>
	</ng-template>
	<div #body>
		&nbsp;
		<p-progressBar mode="indeterminate" *ngIf="loading" [style]="{ height: '6px' }" />
		<div class="grid">
			<div class="col" *ngFor="let item of items">
				<p-card
					 [style]="{maxWidth: '30rem', minWidth: '25rem'}">

					 <ng-template pTemplate="header">
						<header class="px-4">
							<h3 class="p-card-title">{{item.unidade?.sigla_unidade + ' - ' + ((selectedDate | date: 'shortDate')??'')}}</h3>
							<h4 class="p-card-subtitle">Dispositivo: {{item.robo?.dispositivo}}</h4>
						</header>
						<div class="icones">
							<p-button icon="pi pi-map-marker" [rounded]="true" [text]="true" (onClick)="mostraEndereco(item, $event)"></p-button>
							<p-button icon="pi pi-chart-bar" [rounded]="true" [text]="true" (onClick)="mostraGraficos(item)"></p-button>
						</div>
					 </ng-template>

					<p-table>
						<ng-template pTemplate="header">
							<tr>
								<th class="medida">Medida:</th>
								<th class="valor">Valor:</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td class="medida">Quantidade de leituras</td>
								<td class="valor">{{(item.quantidadeTemperaturas)}}</td>
							</tr>
							<tr>
								<td class="medida">Temperatura média</td>
								<td class="valor">{{(item.mediaTemperaturas)| number : '1.1-1'}}°C</td>
							</tr>
							<tr>
								<td class="medida">Luminosidade Mínima</td>
								<td class="valor">{{(item.luzMinima / 45)| number : '1.2-2'}}%</td>
							</tr>
							<tr>
								<td class="medida">Luminosidade Máxima</td>
								<td class="valor">{{(item.luzMaxima / 45) | number : '1.2-2'}}%</td>
							</tr>
							<tr>
								<td class="medida">Luz acesa <i class="pi pi-info-circle"
										pTooltip="Verifica se a luz ficou acesa entre as 21h do dia anterior e as 5h do dia seguinte"></i>
								</td>
								<td class="valor"><span class="status" [class.negativo]="item.luzLigada">{{(item.luzLigada ? 'SIM' :
										'NÃO')}}</span></td>
							</tr>
							<tr>
								<td class="medida">Tempo com janela aberta</td>
								<td class="valor">{{formataMinutos(item.tempoJanelaAberta)}}</td>
							</tr>
							<tr *ngIf="item.ultimaPresenca">
								<td class="medida">Última presença detectada</td>
								<td class="valor">{{item.ultimaPresenca | date: 'short':'-0300'}}</td>
							</tr>
						</ng-template>
					</p-table>

					<ng-template pTemplate="footer" *ngIf="item.unidade?.id_unidade">
						<div class="flex gap-3 mt-1">
							<p-button
								icon="pi pi-microsoft"
								label="Enviar para o Teams"
								class="w-full"
								styleClass="w-full"
								(click)="enviaTeams(item.unidade.id_unidade, this.selectedDate)"
								[loading]="loadingBtns[item.unidade.id_unidade]"
							/>
						</div>
					</ng-template>
				</p-card>
			</div>

			<p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true">
				<ng-template pTemplate="content">
					<div class="painel-localiza">
						<ng-container *ngIf="panelItem.dados; else localizacao_intranet">
							<div class="grid">
								<div class="col-fixed">Prédio:</div>
								<div class="col dado">{{panelItem.dados.predio}}</div>
							</div>
							<div class="grid">
								<div class="col-fixed">Andar:</div>
								<div class="col dado">{{panelItem.dados.andar}}</div>
							</div>
							<div class="grid">
								<div class="col-fixed">Sala:</div>
								<div class="col dado">{{panelItem.dados.sala}}</div>
							</div>
							<div class="grid">
								<div class="col-fixed">Tamanho:</div>
								<div class="col dado">{{panelItem.dados.tamanho | number: '1.0-2'}} m<sup>2</sup></div>
							</div>
						</ng-container>
						<ng-template #localizacao_intranet>
							{{panelItem?.unidade?.localizacao}}
						</ng-template>
					</div>
				</ng-template>
			</p-overlayPanel>
		</div>
	</div>
</p-panel>