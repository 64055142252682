import { AfterContentInit, Component, OnDestroy } from '@angular/core';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { UnidadeService } from 'src/app/modules/servidor/services/unidade.service';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';
import { Unidade } from 'src/app/types/servidor.types';
import { Subscriptions } from 'src/app/types/system.types';
import { Leitura } from '../../services/sust-robo.type';
import { SustRoboService } from '../../services/sust-robo.service';
import { MyMessageService } from 'src/app/modules/shared/services/my-message.service';

@Component({
	selector: 'stj-sust-unidade-edit',
	standalone: true,
	imports: [
		MySharedModule,
		ButtonGroupModule,
	],
	providers: [
		DialogService,
		// SustRoboService,
		UnidadeService,
	],
	templateUrl: './sust-unidade-edit.component.html',
	styleUrl: './sust-unidade-edit.component.scss'
})
export class SustUnidadeEditComponent implements AfterContentInit, OnDestroy
{
	constructor (
		private roboService: SustRoboService,
		private unidadeService: UnidadeService,
		private ref: DynamicDialogRef,
		private config: DynamicDialogConfig,
		private msgService: MyMessageService,
	)
	{
		// this.maxLength = this.roboService.maxLength;
		this.carregaUnidades();
	}

	robo?: Leitura;
	subs: Subscriptions = {};
	roboS: BehaviorSubject<Leitura> = new BehaviorSubject({} as Leitura);
	lastDate?: Date;
	timeOut?: any;
	now: Date = new Date();
	Math = Math;
	unidades: Unidade[] = [];
	loading: boolean = false;
	loadingSave: boolean = false;
	selectedItem?: Unidade;
	total: number = 0;

	ngAfterContentInit(): void
	{
		let robo = this.config?.data?.sensor;
		this.robo = robo;
	}

	ngOnDestroy(): void
	{
		for (let s in this.subs) this.subs[s].unsubscribe();
		if (this.timeOut) clearInterval(this.timeOut);
	}

	async carregaUnidades()
	{
		this.loading = true;
		try
		{
			this.unidades = await this.unidadeService.pegaFilhasFlat();
		} catch (error)
		{
			console.log(error);
		}
		this.loading = false;
	}

	async actionSave()
	{
		if (!this.selectedItem)
		{
			this.msgService.warn('Atenção!', 'Não foi selecionada nenhuma unidade.');
			return;
		}
		this.loadingSave = true;
		try
		{
			let res: any = await this.roboService.salvaUnidadeRobo(this.robo!, this.selectedItem);
			if (!res || !res[0] || !res[0].id)
			{
				throw Error('Houve um erro ao salvar os dados!');
			}
			this.ref.close('save');
			
		} catch (error: any)
		{
			this.msgService.error(error)
		} finally
		{
			this.loadingSave = false;
		}
	}

	async actionClose()
	{
		this.ref.close('close')
	}
}
